.bottom-banner {
  box-sizing: border-box;
  background-color: var(--color-accent-hover);
  color: #fff;
  padding: var(--padding-large);
  display: flex;
  flex-direction: column;
  align-items: center;
  
  &:not(.simple) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: var(--z-index-level8);
  }

  &.simple {
    position: relative;
    background-color: var(--color-bg-3);
    color: var(--color-text);
    padding: var(--padding-medium);
    margin-bottom: var(--padding-large-x);
  }
  
  .bottom-banner-content {
    max-width: 640px;
  }
  
  h2 {
    margin: 0 0 0.5rem 0;
  }
  
  p {
    margin: 0 0 1rem 0;
    text-align: center;
  }
  
  button {
    margin: 0 0.5rem;
  }
  
  .banner-top-bar {
    position: absolute;
    top: var(--padding-small);
    right: var(--padding-small);

    .simple & {
      display: none;
    }
  }

  .banner-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--padding-small-x);

    .simple & {
      display: none;
    }
  }
}
