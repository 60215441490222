.navigation-control {
  $self: &;
  
  &__container {
    position: fixed;
    bottom: var(--padding-medium);
    right: 4vw;
    display: flex;
    gap: 10px;
    background-color: var(--color-bg);
    border-radius: 80px;
    align-items: center;

    @media screen and (max-width: 464px) {
      display: none;
    }
  }

  &__counter {
    width: 2em;
    display: flex;
    justify-content: center;
  }

  &__button {
    transition: background-color 0.3s ease;

    &--flash {
      animation: flash-green 1s;
    }
  }
}

@keyframes flash-green {
  0%, 100% {
    background-color: var(--color-bg);
  }
  50% {
    background-color: var(--color-success);
  }
}
