@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --spacing-small: 8px;
  --spacing-medium: 16px;
  --spacing-large: 24px;
  --spacing-large-x: 40px;
  --spacing-large-xx: 64px;

  // Accent
  --color-accent: hsla(var(--hue), 100%, 40%, 1);
  --color-accent-hover: hsla(var(--hue), 100%, 30%, 1);

  // Text
  --color-text: hsla(var(--hue), 100%, 100%, .9);
  --color-text-secondary: hsla(var(--hue), 100%, 100%, 0.666);
  --color-text-disabled: hsla(var(--hue), 100%, 100%, 0.33);

  // Background
  --color-bg: hsla(var(--hue), 100%, 16%, 1);
  --color-bg-2: hsla(var(--hue), 100%, 24%, 1);
  --color-bg-3: hsla(var(--hue), 100%, 32%, 1);

  // UI
  --padding-small-xx: calc(var(--g) / 2);
  --padding-small-x: calc(var(--g));
  --padding-small: calc(var(--g) * 2);
  --padding-medium: calc(var(--g) * 4);
  --padding-large: calc(var(--g) * 8);
  --padding-large-x: calc(var(--g) * 12);

  // Border
  --border-radius-small-xx: calc(var(--g) / 4);
  --border-radius-small-x: calc(var(--g) / 2);
  --border-radius-small: calc(var(--g));
  --border-radius-medium: calc(var(--g) * 2);
  --border-radius-large: calc(var(--g) * 4);

  // Transitions
  --transition-normal: all 0.2s ease-in-out;
  --transition-fast: all 0.1s ease-in-out;

  // Breakpoints
  // --breakpoint-small: 768px;
  // --breakpoint-medium: 1024px;
  // --breakpoint-large: 1440px;

  // z-index
  --z-index-level1: 100;
  --z-index-level2: 200;
  --z-index-level3: 300;
  --z-index-level4: 400;
  --z-index-level5: 500;
  --z-index-level6: 600;
  --z-index-level7: 700;
  --z-index-level8: 800;
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
  position: fixed;
  scroll-behavior: smooth;
  min-width: 320px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.viewport-style {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  &.light .animated-background {
    opacity: .3;
  }
  &.dark .animated-background {
    opacity: .5;
  }
  // @media screen and (max-width: 464px) {
  //   flex-direction: column-reverse;
  // }
}

.viewport-style {
  position: relative;
  width: 100%;
  height: 100vh;
}

.animated-background {
  // position: absolute;
  z-index: -1;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  // overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.1s ease-out;
}

.animated-background div {
  position: absolute;
  transition: transform 0.3s ease, opacity 0.8s ease, translate 2s ease;
  will-change: transform, opacity;
  transform-origin: center;
}

.animated-background div svg:hover {
  zoom: 1.2 !important;
}

@keyframes flowLightness {
  0% {
    filter: brightness(60%);
  }
  50% {
    filter: brightness(70%);
  }
  100% {
    filter: brightness(60%);
  }
}

@keyframes flowLightnessThemeLight {
  0% {
    filter: brightness(90%);
  }
  50% {
    filter: brightness(100%);
  }
  100% {
    filter: brightness(90%);
  }
}




body {
  font-family: "Jost", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  @media screen and (max-width: 464px) {
    font-size: 13pt;
  }
  line-height: 1.4em;
  color: var(--color-text);
  background-color: var(--color-bg);
  overflow: hidden;
  letter-spacing: -0.03em;
}

p {
  font-family: 'Lexend', sans-serif;
  font-weight: 360;
  font-size: 18px;
  &:first-child {
    margin-top: 0;
  }
}

b {
  font-weight: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.02em;
  margin: var(--padding-small-x) 0;
}
h2 {
  font-size: 3em;
  line-height: 1.045em;
  word-break: break-word;
  font-weight: 700;
  // margin-top: var(--padding-medium);
  letter-spacing: -0.06em;
  text-transform: uppercase;
  // max-width: 10em;
  text-shadow: -8px 8px 0 var(--color-bg);

  @media screen and (max-width: 960px) {
    font-size: 9.45vw;
    // font-size: 9.89vw;
    text-shadow: -4px 4px 0 var(--color-bg);
    // max-width: 12em;
  }

  @media screen and (max-width: 464px) {
    // font-size: 2.6em;
    text-shadow: -4px 4px 0 var(--color-bg);
  }
}
h3 {
  font-size: 2em;
  line-height: 1.1em;
  @media screen and (max-width: 464px) {
    font-size: 1.8em;
  }
}
h5 {
  font-size: 1.1rem;
  letter-spacing: 0.01em;
  font-feature-settings: "smcp", "c2sc";
  font-variant: all-small-caps;
  color: var(--color-text-secondary);
  margin: 0;
  margin-block-start: var(--padding-small-xx);
  // margin-inline-start: var(--padding-small-xx);
}

small {
  font-size: .8em;
  line-height: .7em;
  // letter-spacing: -0.01em;
  color: var(--color-text-secondary);
}

a {
  color: var(--color-accent);
  text-decoration: none;
  position: relative;
  font-weight: 500;
}

a:after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--color-accent);
  content: "";
  opacity: 0;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: opacity 0.3s, -moz-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  -webkit-transform: translateY(0.1em);
  -moz-transform: translateY(0.1em);
  transform: translateY(0.1em);
}

a:hover:after {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  unicode-bidi: isolate;
}

// lista horizontal de icones
.list-icons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
}

.list .description {
  font-size: 1.2em;
  line-height: 1.3em;
  font-style: italic;
  font-weight: 300;
  font-family: serif;
  display: flex;
  text-align: center;
  justify-items: center;
  color: var(--color-text-secondary);
  padding: 1em;
  border: 2px solid var(--color-bg-2);
  background-color: var(--color-bg);
  box-shadow: 8px 8px 0;
  width: fit-content;

  @media screen and (max-width: 464px) {
    font-size: 1.3em;
    padding: var(--padding-small);
    margin: 0 calc(-1 * var(--padding-small-x));
  }
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 67vh;
  color: var(--color-text-disabled);
}

.page {
  .illustration {
    display: flex;
    overflow: hidden;
    pointer-events: none;
    z-index: -1;
    opacity: 0;
    transform: translateY(10px);
    transition: none;
    
    &.visible {
      opacity: 1;
      transform: translateY(0);
      transition: opacity .6s ease-out, transform .6s ease-out;
    }

    svg path {
      stroke-width: 0.5px;
      stroke: rgba($color: #333, $alpha: 1);
    }

    @media screen and (min-width: 961px) {
      left: calc(50vw - var(--page-padding));
      width: 56vw;
      margin-top: -16vh;
      position: fixed;

      .svg-container {
        width: 100%;
        height: 100%;
      }
    }

    @media screen and (max-width: 960px) and (min-width: 465px) {
      left: calc(50vw - var(--page-padding));
      width: 56vw;
      margin-top: -10vw;
      position: fixed;
    }

    @media screen and (max-width: 464px) {
      width: calc(100vw - calc(var(--page-padding) * 2));
      margin-top: -10vw;
      margin-bottom: -16vw;
    }

    svg {
      display: inline-block;
      width: 100%;
      height: 100%;
      max-height: 100vh;
    }
  }
}
