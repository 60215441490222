.projects {
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    font-size: 1.2rem;
  }
  
  &__filter {
    margin-bottom: 30px;
    
    &-title {
      margin-bottom: 15px;
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
  
  &__list {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  &__item {
    border-bottom: 1px solid var(--color-border);
    padding-bottom: var(--padding-large);

    @media (min-width: 768px) {
      padding-bottom: var(--padding-large-x);
    }
    
    &:last-child {
      border-bottom: none;
    }
    
    &-content {
      display: flex;
      flex-direction: column;
      
      @media (min-width: 768px) {
        flex-direction: row;
        gap: 30px;
      }
    }
    
    &-media {
      flex: 0 0 100%;
      margin-bottom: 20px;
      
      @media (min-width: 768px) {
        flex: 0 0 40%;
        margin-bottom: 0;
      }
    }
    
    &-info {
      flex: 1;
    }
    
    &-title {
      margin-top: 0;
      margin-bottom: 15px;
      // font-size: 2rem;
    }
    
    &-tags {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-bottom: 15px;
    }
    
    &-description {
      margin-bottom: 20px;
    }
    
    &-links-title {
      margin-bottom: 10px;
      font-size: 1rem;
    }
    
    &-links {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
}
