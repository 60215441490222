.svg-container {
  display: inline-block; /* Adjust as needed */
}

.pulse-animation path {
  animation: pulse 3s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

/* Define delays for color classes */
.color-0 {
  animation-delay: 0s;
}
.color-1 {
  animation-delay: 0.2s;
}
.color-2 {
  animation-delay: 0.4s;
}
/* Add more classes as needed based on the number of colors */
