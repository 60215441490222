:root {
  --list-gap: var(--spacing-large);
}

.list {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.list-vertical {
    flex-direction: column;

    .list-item {
      flex-direction: column;
    }
  }
  align-items: flex-start;
  gap: 32px;

  > * {
    width: 100%;
  }

  .list-item {
    display: flex;
    flex-direction: row;
    gap: var(--list-gap);
    margin-bottom: var(--padding-large-x);

    @media screen and (max-width: 464px) {
        flex-direction: column;
        gap: 0;
      }

    .col-1 {
      display: flex;
      flex-direction: column;
      gap: 8px;
      flex: 2;
    }

    .col-2 {
      display: flex;
      flex-direction: column;
      gap: 8px;
      flex: 1;
      min-width: 320px;

      @media screen and (min-width: 464px) {
        // box-shadow: -20px 0 0 0 red;
      }
    }
  }

  .col-2 {
    @media screen and (min-width: 464px) {
        // margin: 0 0 0 var(--padding-medium);
    }
  }
}
