.menu-items {
  list-style: none;
  user-select: none;
  display: flex;
  flex-direction: column;

  li {
    padding: var(--padding-small-x) var(--padding-small);
    background-color: var(--color-bg);
    position: relative;
    cursor: pointer;
    border-radius: var(--border-radius-small);

    &.active {
      color: white;
      background-color: var(--color-accent-hover);
    }

    &:not(.active):hover {
      transition: var(--transition-normal);
      background-color: var(--color-bg-2);
    }
  }
}

// .menu-items-reverse {
//     flex-direction: column-reverse;
// }