nav.about {
  background-color: transparent;
}

.container.about {

  @media screen and (max-width: 464px) {
    .content {
      margin-top: 12vw;
    }
  }

  @media screen and (max-width: 960px) and (min-width: 465px) {
    .content {
      margin-top: 32vw;
    }
  }

  .illustration {
    @media screen and (max-width: 960px) {
      .svg-container {
        width: inherit;
      }
    }
  }
  
  .content {
    @media screen and (min-width: 960px) {
      max-width: 36vw;
    }
  }
}

.list-icons {
  a {

    padding: 4px;

    > svg {
      width: 48px;
      height: 48px;
      
      @media (max-width: 960px) {
        width: 44px;
        height: 44px;
      }
    }
  }
}

