:root {
    --page-padding: var(--padding-medium);
}

.page {
    display: flex;
    justify-content: flex-start;
    flex-flow: column;
    padding: var(--padding-small-x) var(--padding-small-x) var(--padding-small-x) var(--padding-small-x);

    @media screen and (min-width: 960px) {
        padding: var(--page-padding) var(--page-padding) var(--page-padding) var(--page-padding);
    }

    &.page-horizontal {
        flex-flow: row;
    }
}