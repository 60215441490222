nav.contact {
  background-color: transparent;
}

.contact {
  @media screen and (max-width: 464px) {
    .content {
      margin-top: 12vw;
    }
  }

  @media screen and (max-width: 960px) and (min-width: 465px) {
    .content {
      margin-top: 40vw;
    }
  }

  @media screen and (min-width: 465px) {
    .page .illustration {
      left: calc(40vw - var(--page-padding));
    }
  }

  .illustration {
    .svg-container {
      width: inherit;
    }
  }
  .content {
    @media screen and (min-width: 960px) {
      max-width: 36vw;
    }
  }
}
