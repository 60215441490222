// TODO: itens impares da .list recebem color red
.container.career {
//   font-size: 0.86em;

  .short-version {
    max-width: 36em;
  }

  // .list > div:nth-child(odd) {
  //   display: flex;
  //   flex-direction: column;
  //   @media screen and (min-width: 961px) {
  //       text-align: right;
  //       width: 50%;
  //       align-self: flex-start;
  //   }
  //   > div {
  //     display: flex;
  //     @media screen and (min-width: 961px) {
  //         align-items: flex-end;
  //     }
  //     flex-direction: column;
  //   }
  // }

  // .list > div:nth-child(even) {
  .list > div {
    text-align: left;
    // max-width: 512px;
    @media screen and (min-width: 961px) {
        max-width: 36em;
        // align-self: flex-end;
    }
    display: flex;
    flex-direction: column;
  }
  @media screen and (min-width: 961px) {
    // .list > div:not(:first-child) > div {
    //     margin-top: 2em;
    // }
    .list > div > div {
        // padding: 1em;
    }
}
}
