.not-found-page {
    text-align: center;
    padding: 2em;
  }
  
  .not-found-svg {
    width: 150px;
    height: 150px;
    margin-bottom: 1em;
  }
  