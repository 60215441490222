nav.blog {
  background-color: transparent;
}

.blog-container {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.container.blog {
  .page {
    padding-bottom: 0;
  }
}

.blog {
  .illustration {
    @media screen and (max-width: 960px) {
      .svg-container {
        width: inherit;
      }
    }
  }


  figure {
    margin: 0;

    img {
      width: 100%;
      height: auto;
      image-rendering: auto;
    }
  }
}
