:root {
  --nav-button-icon: 48px;
  --nav-height: 128px;
  --nav-shadow: hsla(0, 0%, 0%, 0.3);

  @media screen and (max-width: 960px) {
    --nav-height: 80px;
  }
}

.nav {
  $self: &;
  background-color: transparent;
  display: flex;
  justify-content: center;
  letter-spacing: -.034em;
  padding-left: 4vw;
  padding-right: 4vw;
  user-select: none;
  font-family: 'Jost', sans-serif;

  @media screen and (max-width: 464px) {
    padding-left: var(--padding-small);
    padding-right: var(--padding-small);
  }

  &__options {
    background-color: var(--color-bg-3);
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
    transition: width 0.3s ease-in-out 0s;
    width: 0;
    min-width: 0;
    z-index: 9000;

    @media screen and (max-width: 464px) {
      flex-direction: column-reverse;
    }

    &--opened {
      height: 100%;
      transition: width 0.3s ease-in-out 0s;
      width: 32vw;

      // @media screen and (min-width: 961px) {
      //   width: 400px;
      // }

      @media screen and (max-width: 960px) and (min-width: 465px) {
        width: 400px;
      }

      @media screen and (max-width: 464px) {
        width: 100vw;
      }
    }
  }

  &__option {
    display: flex;
    font-size: 2.6rem;
    line-height: 1.6em;
    list-style: none;

    a {
      color: var(--color-text-body);
      position: relative;
      z-index: 10002;

      &:after {
        height: 8px;
        top: 1.2em;
        z-index: 10001;
      }

      &:hover, &.active {
        &:after {
          opacity: 1;
        }
      }

      &.active {
        &:after {
          height: 8px;
        }
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    height: fit-content;
    justify-content: center;
    margin-top: var(--nav-height);
    opacity: 0;
    transition: opacity 0.15s ease-in-out 0s;
    padding-left: var(--padding-large);
    padding: 4vw;

    @media screen and (max-width: 464px) {
      padding-left: 0;
      align-items: center;
      height: 100%;
    }
  }

  &--opened {
    #{$self}__list {
      opacity: 1;
      transition: opacity 0.2s ease-in-out 0.1s;
    }
  }

  &__trigger {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 1em;
    height: var(--nav-height);
    justify-content: space-between;
    line-height: 72px;
    width: 100%;

    @media screen and (max-width: 464px) {
      justify-content: right;
      height: var(--nav-height);
    }
  }

  &__button {
    color: var(--color-accent);
    cursor: pointer;
    font-size: var(--nav-button-icon);
    height: var(--nav-button-icon);
    position: sticky;
    width: var(--nav-button-icon);
    z-index: 9001;
  }

  &__page-title {
    font-size: 48px;

    @media screen and (max-width: 464px) {
      font-size: 2em;
    }
  }

  &__name {
    color: var(--color-text-secondary);
    flex: 1;
    font-size: 48px;
    font-weight: 200;
    letter-spacing: -.034em;

    @media screen and (max-width: 960px) {
      display: none;
    }

    a {
      font-weight: 600;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    padding: 1em;
    margin-top: auto;

    a {
      margin-bottom: 1em;
      text-align: center;
      color: var(--color-text-body);
      text-decoration: none;
      font-size: 2rem;

      &:hover {
        color: var(--color-accent);
      }
    }
  }
}
