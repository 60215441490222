// src/components/content/content.scss
.page .content,
.page .content-with-menu {

  @media screen and (max-width: 960px) {
    margin-top: 0;

    .blog-container {
      gap: var(--padding-large);
      flex-direction: column;

      .content-menu {
        width: 100%;
      }
    }
  }
}

.content-with-menu {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 960px) {
    flex-direction: column-reverse;
  }

  width: 100%;
  gap: var(--padding-medium);
}
// .content-view {
//   overflow-y: auto;
//   scroll-behavior: smooth;
//   height: 100%;
// }

.content-view {
  display: flex;
  flex-flow: column;
  flex: 1;
  width: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  height: calc(100vh - var(--nav-height) - var(--page-padding)); // calc(2 * var(--page-padding))
  // overflow: auto;
  box-sizing: border-box;
  scrollbar-color: var(--color-bg-2) var(--color-bg);
  scrollbar-width: thin;
  padding-bottom: var(--padding-large-x);

  article {
    max-width: 36em;
  }

  &::-webkit-scrollbar {
    width: 16px;
  }

  .tag {
    margin: 0;
    color: var(--color-text);
  }

  .content-info {
    color: var(--color-text-secondary);
    font-size: .78em;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--padding-small);
    margin-bottom: var(--padding-medium);
  }
}

.content-menu {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 320px;
  align-items: flex-end;

  @media screen and (max-width: 1440px) and (min-width: 961px) {
    width: 30vw;
    max-width: 320px;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
  }
}

.menu-list {
  list-style: none;

  li {
    padding: var(--padding-small-x) var(--padding-small);
    background-color: var(--color-bg);
    position: relative;
    cursor: pointer;
    border-radius: var(--border-radius-small);

    &.active {
      color: white;
      background-color: var(--color-accent-hover);
    }

    &:not(.active):hover {
      transition: var(--transition-normal);
      background-color: var(--color-bg-2);
    }
  }
}
