:root {
  --container-padding: 4vw;
  --page-padding: var(--padding-medium);
  --page-padding-bottom: var(--padding-large-x);
  --page-min-width: 961px;
  --page-max-width: 1440px;

  @media screen and (min-width: 960px) {
    --page-padding: var(--padding-large);
    --page-padding-bottom: calc(var(--padding-large-x));
  }
  @media screen and (max-width: 464px) {
    --page-padding: var(--padding-small);
    --container-padding: var(--padding-small);
  }
}

.container {
  display: flex;
  justify-content: flex-start;
  flex-flow: column;
  flex-direction: column;
  overflow: auto;
  align-items: center;
  height: calc(100vh - var(--nav-height));
  min-height: calc(100vh - var(--nav-height));
  scrollbar-color: var(--color-bg-2) var(--color-bg);
  scrollbar-width: thin;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 16px;
  }

  .page {
    padding-left: var(--page-padding);
    padding-top: var(--page-padding);
    padding-right: var(--page-padding);
    padding-bottom: var(--page-padding-bottom);
    @media screen and (max-width: 959px) {
      padding-bottom: var(--page-padding-bottom);
    }
    max-width: var(--page-max-width);
    width: 100%;
    box-sizing: border-box;
    flex: 1;

    // p {
    //   background-color: var(--color-bg);
    // }
  }
}
