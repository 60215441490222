section.hero {
  font-style: normal;
  font-weight: 800;
  font-size: 156px;
  display: flex;
  // height: 100%;
  align-items: flex-end;
  box-sizing: border-box;
  justify-content: center;
  line-height: 0.86em;
  letter-spacing: -0.16em;
  text-transform: uppercase;
  user-select: none;
  // margin-top: -2vh;
  flex-direction: column;


  @media screen and (min-width: 961px) and (max-width: 1440px) {
    font-size: 11vw;
  }

  @media screen and (min-width: 961px) {
    // top: auto;
    // display: flex;
    // position: fixed;
    // max-height: 40vh;
    height: 100%;
    margin-right: calc(var(--padding-medium) + var(--padding-small));
  }

  @media screen and (min-width: 465px) and (max-width: 960px) {
    font-size: 12vw;
    bottom: 180px;
        position: fixed;
        right: var(--padding-large);
  }

  @media screen and (max-width: 464px) {
    font-size: 18vw;
    align-items: center;
    // margin-top: 1em;
    // padding-bottom: var(--padding-large-x);
    margin-top: -2vw;
  }

  // hero span
  span {
    display: inline;
    text-shadow: -0.067em 0.078em 0 var(--color-bg);
  }
}

.home .page {

  .illustration {
    // width: 100%;
    // height: 100vw;
    // right: auto;
    // top: var(--header-height);

    // left: -2vw;
    top: 10vh;
    bottom: 0;
    left: 0;
    width: 100%;
    
    
    
    @media screen and (min-width: 961px) {
      top: 16vh;
      bottom: 0;
      left: 0;
      width: 69vw;
    }

    // }
    @media screen and (max-width: 960px) and (min-width: 465px) {
      top: 0;
    }
    @media screen and (max-width: 464px) {

      // top: -16vw;
      .svg-container {
        width: 100%;
      }
    }
  }
}

.container.home {
  .page {
    padding: var(--padding-medium) var(--padding-medium) var(--padding-large-x) var(--padding-medium);
    // padding-bottom: var(--padding-large-x);
    // max-width: 1024px;

    @media screen and (max-width: 464px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}