:root {
    --footer-height: 128px;
}

footer {
    display: flex;
    padding: 0 0 var(--padding-medium) var(--padding-medium);
    box-sizing: border-box;
    width: 100%;

    @media screen and (max-width: 960px) {
        display: none;
        // justify-content: center;
        // padding: var(--padding-medium) 0;

    }
}