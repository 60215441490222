:root {
  --btn-text-color: white;
  --btn-outline: var(--color-text);
  --btn-disabled: var(--color-text-disabled);
}

.btn {
  cursor: pointer;
  user-select: none;
  padding: 16px 24px;
  border: 0;
  border-radius: var(--border-radius-large);
  color: var(--btn-text-color);
  font-weight: 700;
  font-size: 24px;
  display: inline-flex;
  transition: box-shadow 0.2s ease-out;
  background-color: transparent;
  box-shadow: 0 0 0 4px var(--color-accent) inset;

  &:disabled {
    cursor: default;
    box-shadow: 0 0 0 4px var(--btn-disabled) inset;
    color: var(--btn-disabled);
  }

  > svg {
    width: 1.2em;
    height: 1.2em;
    margin-left: 0.3em;
    margin-top: 0.1em;
  }

  &.btn-primary {
    color: var(--btn-text-color);
    box-shadow: 0 0 0 4px var(--btn-outline) inset;
    background-color: var(--color-accent);

    &:not([disabled]):hover {
      box-shadow: 0 0 0 8px var(--btn-outline) inset;
    }
  }

  &.btn-selectable {
    padding: 4px 8px;
    background-color: transparent;
    color: var(--color-ui-interaction);
  }

  &.only-icon {
    padding: 0;
    color: inherit;
    background: transparent;
    height: 80px;
    width: 80px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    > svg {
      width: 40px;
      height: 40px;
      margin: 0;
    }

    @media screen and (max-width: 960px) {
      height: 64px;
      width: 64px;
    }

    &.crude-icon {
      svg {
        fill: inherit;
      }
    }

    &:disabled {
      cursor: default;
      box-shadow: 0 0 0 4px var(--btn-disabled) inset;
      color: var(--btn-disabled);
    }
  }

  span {
    margin-left: 4px;
  }

  &:not([disabled]):hover {
    box-shadow: 0 0 0 8px var(--color-accent) inset;
  }

  &:not(.only-icon):hover {
    // outline: 2px solid var(--color-accent-hover);
  }
}

.links-buttons {
  display: flex;
  gap: var(--padding-small-x);
  flex-flow: column;
}

.button-list {
  display: flex;
  gap: 8px;
  width: fit-content;
  margin: var(--padding-medium) 0;

  @media screen and (max-width: 464px) {
    justify-content: center;
  }
}
