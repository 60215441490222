:root {
  --select-text-color: var(--color-text);
  --select-text-option-color: white;
  --select-outline: var(--color-accent);
  --select-outline-width: 8px;
  --select-disabled: var(--color-text-disabled);
  --select-min-height: 56px;
  --select-border-radius-out: calc(var(--select-min-height) / 2);
  --select-border-radius-in: calc(var(--select-border-radius-out) - var(--select-outline-width));
}

.select-box {
  $self: &;
  position: relative;
  display: block;
  user-select: none;
  box-sizing: border-box;
  border: 0;
  border-radius: var(--select-border-radius-out);
  color: var(--select-text-color);
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  display: inline-flex;
  transition: box-shadow 0.2s ease-out;
  background-color: transparent;
  box-shadow: 0 0 0 4px var(--color-accent) inset;

  &:disabled {
    cursor: default;
    box-shadow: 0 0 0 4px var(--select-disabled) inset;
    color: var(--select-disabled);
  }

  &:not([disabled]):hover,
  &--open {
    box-shadow: 0 0 0 8px var(--select-outline) inset;
  }

  &--open {
    #{$self}__current svg {
      transform: rotate(180deg);
      transition: var(--transition-fast);
    }
  }

  &__current {
    position: relative;
    padding: 16px 24px;
    display: flex;
    box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    outline: none;
    justify-content: space-between;
    width: 100%;
    border-radius: inherit;
    align-items: center;

    svg {
      transition: var(--transition-fast);
      flex-shrink: 0;
    }
  }

  &__value {
    display: flex;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 8px;
  }

  &__placeholder {
    color: var(--color-text-disabled);
    font-weight: 400;
    font-style: italic;
  }

  &__list {
    position: absolute;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    z-index: var(--z-index-level1);
    opacity: 1;
    left: 0;
    top: calc(100% - var(--select-outline-width));
    border-radius: var(--select-border-radius-out);
    margin-top: 0;
    overflow: hidden;
    background-color: var(--color-accent);
    max-height: 300px;
    overflow-y: auto;

    li {
      &:first-child {
        margin-top: 8px;
      }

      &:last-child {
        margin-bottom: 8px;
      }
    }
  }

  &__option {
    color: var(--select-text-option-color);
    display: flex;
    align-items: center;
    padding: 8px 16px;
    margin: 0 8px;
    border-radius: var(--select-border-radius-in);
    transition: var(--transition-fast);
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: var(--color-accent-hover);
    }

    &--selected {
      background-color: var(--color-accent-hover);
      font-weight: 700;
    }
  }

  &__checkbox {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.2);

    svg {
      width: 14px;
      height: 14px;
      color: var(--select-text-option-color);
    }
  }

  // Estilos específicos para multi-select
  &--multi {
    #{$self}__list {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    #{$self}__option {
      justify-content: flex-start;
    }
  }
}
