div {
  .image-preview, 
  .video-container,
  .video-preview {
    display: flex;
    aspect-ratio: auto 1280 / 800; // 900 / 560
    @media screen and (max-width: 960px) {
      width: 100%;
    }
    // margin-bottom: var(--padding-medium);
    overflow: hidden;
    transition: var(--transition-normal);
    background-size: auto 100%;
    background-position: center;
    cursor: pointer;
// }

// .video-container,
// .video-preview {
  border-radius: var(--border-radius-large);
    box-shadow: 0 0 0 8px var(--color-accent) inset;
    &:hover {
      box-shadow: 0 0 0 0 var(--color-accent) inset;
      background-size: auto 105%;
    }
    iframe {
      display: flex;
      height: 100%;
    }
  }
}


.video-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  
  > svg {
    width: 72px;
    height: 72px;
    transition: var(--transition-normal);
    align-self: center;
    color: var(--color-accent);
    position: relative;
    background-color: white;
    border-radius: 64px;
    font-size: 24px;

  }
  > svg svg {
    width: 64px;
    height: 64px;
  }
  &:hover {
    opacity: 1;

    // svg {
    //   transition: var(--transition-normal);
    //   color: var(--color-accent-hover);
    // }

    &:before {
      width: 104px;
      height: 104px;
    }
  }
}
.video-preview.video-preview-open {
  cursor: default;

  .video-modal {
    display: flex;
    flex-flow: column;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: hsla(0, 0, 0, 0.96);
    z-index: 10000;

    .btn {
      position: fixed;
      top: var(--padding-medium);
      color: white;
    }
  }

  .video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    height: 80vh;
  }
}
.video-modal {
  display: none;

  .btn {
    z-index: 1;
  }
}
