:root {
  --tag-highlight-bg: var(--color-bg-3);
  --tag-highlight-text: var(--color-text);
  --tag-active-bg: var(--color-accent);
  --tag-active-bg-over: var(--color-bg-3);
  --tag-active-text: white;
  --tag-disabled-bg: transparent;
  --tag-disabled-text: var(--color-text-disabled);
  --tag-bg: var(--color-bg-2);
  --tag-selected-bg: var(--color-accent);
  --tag-selected-text: white;
  --tag-toggleable-padding: var(--padding-small-x);
  --tag-toggleable-text-size: 1em;
  --tag-toggleable-bg: var(--color-bg-2);
  --tag-toggleable-text: var(--color-ui-text-base);
  --tag-toggleable-hover-bg: var(--color-bg-3);
}

.tag {
  font-family: 'Jost', sans-serif;
  opacity: 0.7;
  display: inline-flex;
  background-color: var(--tag-bg);
  color: var(--color-ui-text-base);
  font-weight: 500;
  padding: .1rem .6rem .3rem .6rem;
  font-size: 1.1rem;
  letter-spacing: -.036em;
  font-feature-settings: "smcp", "c2sc";
  font-variant: all-small-caps;
  margin-right: var(--padding-small-xx);
  margin-bottom: var(--padding-small-xx);
  border-radius: var(--border-radius-small);
  line-height: 1rem;
  user-select: none;
  transition: all 0.2s ease;

  &--clickable {
    background-color: hsla(
      var(--hue-interaction),
      100%,
      var(--l-interaction),
      0.1
    );
    color: var(--color-ui-interaction);
    cursor: pointer;
    font-size: 1.3rem;
    padding: 2px 8px 6px 8px;
    border-radius: var(--border-radius-small);
    
    &:hover {
      background-color: var(--tag-active-bg-over);
    }
  }

  &--highlight {
    background-color: var(--tag-highlight-bg);
    color: var(--tag-highlight-text);
    opacity: 1;
    font-weight: 500;
  }

  &--active {
    background-color: var(--tag-active-bg);
    color: var(--tag-active-text);
    cursor: pointer;

    &:hover {
      background-color: var(--color-accent-hover);
    }
  }

  &--disabled {
    background-color: var(--tag-disabled-bg);
    color: var(--tag-disabled-text);
    cursor: default;

    &:hover {
      background-color: inherit;
    }
  }
  
  &--toggleable {
    background-color: var(--tag-toggleable-bg);
    color: var(--tag-toggleable-text);
    padding-right: var(--padding-medium);
    padding-left: var(--tag-toggleable-padding);
    border-radius: var(--border-radius-medium);
    font-size: var(--tag-toggleable-text-size);
    cursor: pointer;
    opacity: 0.8;
    
    &:hover {
      background-color: var(--tag-toggleable-hover-bg);
      opacity: 1;
    }
  }
  
  &--selected {
    background-color: var(--tag-selected-bg);
    color: var(--tag-selected-text);
    opacity: 1;
    font-weight: 500;
    
    &:hover {
      background-color: var(--color-accent-hover);
    }
  }
}

.tags-group {
  margin-bottom: var(--padding-small-x);
  line-height: .7em;
}

.tags-filter {
  margin-bottom: var(--padding-large-x);
  display: flex;
  flex-wrap: wrap;
}
